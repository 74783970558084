import { useMe } from "api-hooks/index"
import { ClerkLoaded } from "@clerk/nextjs"
import { SlowRedirect } from "core/components/SlowRedirect"
import { Container } from "@mui/material"
import Head from "next/head"
import { useIsAuthenticated } from "core/hooks"

export const HomePage = () => {
  const isAuthenticated = useIsAuthenticated()
  const { data: user } = useMe()
  const redirect = isAuthenticated
    ? `/workspaces/${user?.preferred_workspace?.workspace_id}/devices`
    : "/signin"
  return (
    <Container>
      <Head>
        <title>Seam</title>
        <link rel="shortcut icon" href="/favicon-light.svg" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <ClerkLoaded>
        <SlowRedirect to={redirect} />
      </ClerkLoaded>
    </Container>
  )
}

export default HomePage
